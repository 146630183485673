<template>
  <div class="uploader">
    <form enctype="multipart/form-data" novalidate class="m-0 p-0">
      <div class="dropbox">
        <input id="uploader" class="file-input" type="file" :disabled="isSaving" @change="filesChange($event.target.files)" :accept="acceptType" ref="uploader"/>
        <div class="image-selector pointer" @click="$refs.uploader.click()">
          <i class="fas fa-file-upload"></i>
          <span class="mx-2" v-if="isInitial">{{$t('designer.uploader.toUpload')}}</span>
          <span class="mx-2" v-if="isSaving">{{$t('designer.uploader.isSaving', { name: fileName })}}</span>
          <span class="mx-2" v-if="isSuccess">{{$t('designer.uploader.isSuccess', { name: fileName })}}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import i18n from './i18n'
import Vue from 'vue'
import JSZip from 'jszip'
const MAX_SIZE = 2097152
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export const isImage = ({ data = '', filename = '' }) => data.startsWith('data:image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(filename)
export const isHTML = ({ data = '', filename = '' }) => data.startsWith('data:text/html') || (/\.(html|html|xhtml|xml)$/i).test(filename)
export const isPython = ({ data = '', filename = '' }) => data.startsWith('data:application/python') || (/\.(py)$/i).test(filename)
export const isJavascript = ({ data = '', filename = '' }) => data.startsWith('data:text/javascript') || (/\.(js)$/i).test(filename)
export const isJSON = ({ data = '', filename = '' }) => data.startsWith('data:application/json') || (/\.(json)$/i).test(filename)
export const isCSS = ({ data = '', filename = '' }) => data.startsWith('data:text/css') || (/\.(css|scss)$/i).test(filename)
export const isJAR = ({ data = '', filename = '' }) => data.startsWith('data:application/java-archive') || (/\.(css|scss)$/i).test(filename)
export const isTextPlain = ({ data = '', filename = '' }) => data.startsWith('data:text/plain') || (/\.(txt)$/i).test(filename)
export const isUndefinedFile = ({ data = '', filename = '' }) => !isImage({ data, filename }) && !isHTML({ data, filename }) && !isPython({ data, filename }) && !isJavascript({ data, filename }) && !isJSON({ data, filename }) && !isCSS({ data, filename }) && !isTextPlain({ data, filename })

export default {
  name: 'uploader',
  props: {
    simpleFile: {
      default: false,
      type: Boolean
    },
    acceptType: {
      required: false,
      default: 'application/zip',
      type: String
    },
    maxSize: {
      required: false,
      default: MAX_SIZE,
      type: Number
    }
  },
  data () {
    return {
      currentStatus: null,
      fileName: ''
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.reset()
    },
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
    },
    uploadOk () {
      // reset form to initial file content, to detect again the same file
      this.currentStatus = STATUS_SUCCESS
      const uploader = this.$refs.uploader
      uploader.type = 'text'
      uploader.type = 'file'
    },
    filesChange (fileList) {
      this.currentStatus = STATUS_SAVING
      if (!fileList.length) {
        this.reset()
        return
      }
      Vue.set(this, 'fileName', fileList[0].name)
      this.getFileByType(fileList)
    },
    async getZipFile (file) {
      const zip = await JSZip.loadAsync(file)
      return zip
    },
    getSimpleFile (e, file) {
      const typeImage = isImage({ data: e.currentTarget.result })
      const typeHtml = isHTML({ data: e.currentTarget.result })
      const typeJavascript = isJavascript({ data: e.currentTarget.result })
      const typeJSON = isJSON({ data: e.currentTarget.result })
      const typeCSS = isCSS({ data: e.currentTarget.result })
      const typeJAR = isJAR({ data: e.currentTarget.result })
      const typeTextPlain = isTextPlain({ data: e.currentTarget.result })
      const typeUndefined = isUndefinedFile({ data: e.currentTarget.result })

      let data = {
        data: typeImage || typeJAR ? e.currentTarget.result : decodeURIComponent(escape(atob(e.currentTarget.result.split(',')[1]))),
        base64Data: e.currentTarget.result,
        isImage: typeImage,
        isHtml: typeHtml,
        isJavascript: typeJavascript,
        isJSON: typeJSON,
        isCSS: typeCSS,
        isJar: typeJAR,
        isTextPlain: typeTextPlain,
        isUndefinedFile: typeUndefined,
        file
      }
      return data
    },
    getFileByType (fileList) {
      let reader = new FileReader()
      reader.onload = async (e) => {
        let file = fileList[0]
        if (file.size === 0) {
          this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg: 'designer.uploader.erroremptyfile' })
          this.currentStatus = STATUS_FAILED
          return
        }
        if (file.size > this.maxSize) {
          this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg: 'designer.uploader.errormaxsize' })
          this.currentStatus = STATUS_FAILED
          return
        }
        if (this.acceptType.indexOf(file.type) === -1) {
          this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg: 'designer.uploader.invalidType' })
          this.currentStatus = STATUS_FAILED
          return
        }
        try {
          let data
          if (this.simpleFile) {
            data = this.getSimpleFile(e, file)
          } else {
            data = await this.getZipFile(file)
          }
          this.uploadOk()
          this.$emit('loadFile', { filename: file.name, content: data })
        } catch (e) {
          this.currentStatus = STATUS_FAILED
          this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg: 'designer.uploader.errorreadingfile' })
        }
      }
      reader.readAsDataURL(fileList[0])
    }
  }
}
</script>

<style scoped lang="scss">
  .uploader {
    .image-selector:hover {
      color: $color-base;
    }
    [type="file"] {
      display: none;
    }
  }
</style>
